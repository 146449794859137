// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remixui_tooltip {
    z-index: 1001;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    min-height: 50px;
    padding: 16px 24px 12px;
    border-radius: 3px;
    left: 40%;
    font-size: 14px;
    text-align: center;
    bottom: -0px;
    flex-direction: row;
}
@keyframes remixui_animatebottom  {
  0% {bottom: -300px}
  100% {bottom: 0px}
}
@keyframes remixui_animatetop  {
  0% {bottom: 0px}
  100% {bottom: -300px}
}
.remixui_animateTop {
  -webkit-animation-name: remixui_animatetop;
  -webkit-animation-duration: 2s;
  animation-name: remixui_animatetop;
  animation-duration: 2s;
}
.remixui_animateBottom {
  -webkit-animation-name: remixui_animatebottom;
  -webkit-animation-duration: 2s;
  animation-name: remixui_animatebottom;
  animation-duration: 2s;    
}
`, "",{"version":3,"sources":["webpack://./../../libs/remix-ui/toaster/src/lib/toaster.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;AACvB;AAKA;EACE,IAAI,cAAc;EAClB,MAAM,WAAW;AACnB;AAKA;EACE,IAAI,WAAW;EACf,MAAM,cAAc;AACtB;AACA;EACE,0CAA0C;EAC1C,8BAA8B;EAC9B,kCAAkC;EAClC,sBAAsB;AACxB;AACA;EACE,6CAA6C;EAC7C,8BAA8B;EAC9B,qCAAqC;EACrC,sBAAsB;AACxB","sourcesContent":[".remixui_tooltip {\n    z-index: 1001;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    position: fixed;\n    min-height: 50px;\n    padding: 16px 24px 12px;\n    border-radius: 3px;\n    left: 40%;\n    font-size: 14px;\n    text-align: center;\n    bottom: -0px;\n    flex-direction: row;\n}\n@-webkit-keyframes remixui_animatebottom  {\n  0% {bottom: -300px}\n  100% {bottom: 0px}\n}\n@keyframes remixui_animatebottom  {\n  0% {bottom: -300px}\n  100% {bottom: 0px}\n}\n@-webkit-keyframes remixui_animatetop  {\n  0% {bottom: 0px}\n  100% {bottom: -300px}\n}\n@keyframes remixui_animatetop  {\n  0% {bottom: 0px}\n  100% {bottom: -300px}\n}\n.remixui_animateTop {\n  -webkit-animation-name: remixui_animatetop;\n  -webkit-animation-duration: 2s;\n  animation-name: remixui_animatetop;\n  animation-duration: 2s;\n}\n.remixui_animateBottom {\n  -webkit-animation-name: remixui_animatebottom;\n  -webkit-animation-duration: 2s;\n  animation-name: remixui_animatebottom;\n  animation-duration: 2s;    \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
