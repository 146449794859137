// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statusMessage {
  margin-left: 15px;
}
.debuggerLabel {
  margin-bottom: 2px;
  font-size: 11px;
  line-height: 12px;
  text-transform: uppercase;
}
.debuggerConfig {
  display: flex;
  align-items: center;
}
.debuggerConfig label {
  margin: 0;
}
.validationError {
  overflow-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./../../libs/remix-ui/debugger-ui/src/lib/debugger-ui.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,SAAS;AACX;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".statusMessage {\n  margin-left: 15px;\n}\n.debuggerLabel {\n  margin-bottom: 2px;\n  font-size: 11px;\n  line-height: 12px;\n  text-transform: uppercase;\n}\n.debuggerConfig {\n  display: flex;\n  align-items: center;\n}\n.debuggerConfig label {\n  margin: 0;\n}\n.validationError {\n  overflow-wrap: break-word;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
