import yo from 'yo-yo'
export function basicLogo () {
  return yo`
<svg id="tronlogo" data-name="tronlogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 440">
  <defs>
    <style>
      .cls-1 {
        fill: #262729;
      }

      .cls-2 {
        fill: #eb0029;
      }
    </style>
  </defs>
  <title>tronlogo</title>
  <rect class="cls-1" x="28.42" y="342.67" width="10.62" height="69.43" />
  <rect class="cls-1" y="313.29" width="86.68" height="10.62" />
  <rect class="cls-1" x="47.64" y="342.67" width="10.62" height="69.43" />
  <polygon class="cls-1" points="352.05 412.2 362.78 412.2 362.78 370.38 352.05 358.44 352.05 412.2" />
  <polygon class="cls-1" points="408.55 313.29 408.55 390.81 332.62 306.43 332.62 412.2 343.36 412.2 343.36 334.63 419.28 419 419.28 313.29 408.55 313.29" />
  <path class="cls-1" d="M304.4,356.27a49.46,49.46,0,1,0,49.46,49.46A49.52,49.52,0,0,0,304.4,356.27Zm0,88.26a38.8,38.8,0,1,1,38.8-38.8A38.85,38.85,0,0,1,304.4,444.53Z" transform="translate(-41.23 -43.04)" />
  <circle class="cls-1" cx="263.17" cy="362.68" r="7.13" />
  <path class="cls-1" d="M236.32,386.65a30.26,30.26,0,0,0-30.13-30.32H149.62v98.91h10.56V367.08h46a19.6,19.6,0,0,1,.21,39.18l-37.55,0v49h10.56V417h24.75l20.21,38.26h12.27l-21.11-39.77A30.54,30.54,0,0,0,236.32,386.65Z" transform="translate(-41.23 -43.04)" />
  <path class="cls-2" d="M321.13,78,131.35,43l99.88,251.32L370.39,124.81Zm-3.05,15.39,29,27.6-79.4,14.38Zm-67.62,39.1L166.76,63,303.55,88.21Zm-6,12.28L230.86,257.55,157.27,72.39Zm12.63,6L345,134.8,244.2,257.67Z" transform="translate(-41.23 -43.04)" />
</svg>`
}
