// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    display: flex;
    align-items: center;
  }
  .name {
    font-weight: bold;
  }
  .nameDetail {
    font-weight: bold;
    margin-left: 3px;
  }
  .icon {
    margin-right: 5%;
  }
  .eyeButton {
    margin: 3px;
  }
  .dropdownpanel {
    width: 100%;
    word-break: break-word;
  }
  .dropdownrawcontent {
    padding: 2px;
    word-break: break-word;
  }
  .message {
    padding: 2px;
    word-break: break-word;
  }
  .refresh {
    display: none;
    margin-left: 4px;
    margin-top: 4px; 
    animation: spin 2s linear infinite;
  }
  .cursor_pointer {
    cursor: pointer;
  }
  @keyframes spin {
    to {transform:rotate(359deg);}
  }`, "",{"version":3,"sources":["webpack://./../../libs/remix-ui/debugger-ui/src/lib/vm-debugger/styles/dropdown-panel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;IACX,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,kCAAkC;EACpC;EACA;IACE,eAAe;EACjB;EAOA;IACE,IAAI,wBAAwB,CAAC;EAC/B","sourcesContent":[".title {\n    display: flex;\n    align-items: center;\n  }\n  .name {\n    font-weight: bold;\n  }\n  .nameDetail {\n    font-weight: bold;\n    margin-left: 3px;\n  }\n  .icon {\n    margin-right: 5%;\n  }\n  .eyeButton {\n    margin: 3px;\n  }\n  .dropdownpanel {\n    width: 100%;\n    word-break: break-word;\n  }\n  .dropdownrawcontent {\n    padding: 2px;\n    word-break: break-word;\n  }\n  .message {\n    padding: 2px;\n    word-break: break-word;\n  }\n  .refresh {\n    display: none;\n    margin-left: 4px;\n    margin-top: 4px; \n    animation: spin 2s linear infinite;\n  }\n  .cursor_pointer {\n    cursor: pointer;\n  }\n  @-moz-keyframes spin {\n    to { -moz-transform: rotate(359deg); }\n  }\n  @-webkit-keyframes spin {\n    to { -webkit-transform: rotate(359deg); }\n  }\n  @keyframes spin {\n    to {transform:rotate(359deg);}\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
