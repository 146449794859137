// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
    display: flex;
    flex-wrap: wrap;
}
.stepButtons {
    width: 100%;
    display: flex;
    justify-content: center;
}
.stepButton {
}
.jumpButtons {
    width: 100%;
    display: flex;
    justify-content: center;
}
.jumpButton {
}
.navigator {
}
.navigator:hover {
}`, "",{"version":3,"sources":["webpack://./../../libs/remix-ui/debugger-ui/src/lib/button-navigator/button-navigator.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB;AACA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;AACA;AACA;AACA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;AACA;AACA;AACA;AACA;AACA;AACA","sourcesContent":[".buttons {\n    display: flex;\n    flex-wrap: wrap;\n}\n.stepButtons {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n.stepButton {\n}\n.jumpButtons {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n.jumpButton {\n}\n.navigator {\n}\n.navigator:hover {\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
