// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remixui_label {
    margin-top        : 4px;
}
.remixui_leaf {
    overflow          : hidden;
    text-overflow     : ellipsis;
    width             : 90%;
    margin-bottom     : 0px;
}
.remixui_fileexplorer       {
    box-sizing        : border-box;
    -webkit-user-select       : none;
       -moz-user-select       : none;
            user-select       : none;
}
input[type="file"] {
    display: none;
}
.remixui_folder,
.remixui_file               {
    font-size         : 14px;
    cursor            : pointer;
}
.remixui_file               {
    padding           : 4px;
}
.remixui_newFile            {
    padding-right     : 10px;
}
.remixui_newFile i          {
    cursor            : pointer;
}
.remixui_newFile:hover    {
    transform         : scale(1.3);
}
.remixui_menu               {
    margin-left       : 20px;
}
.remixui_items              {
    display           : inline
}
.remixui_remove             {
    margin-left       : auto;
    padding-left      : 5px;
    padding-right     : 5px;
}
.remixui_activeMode         {
    display           : flex;
    width             : 100%;
    margin-right      : 10px;
    padding-right     : 19px;
}
.remixui_activeMode > div   {
    min-width         : 10px;
}
ul                  {
    padding           : 0;
}
`, "",{"version":3,"sources":["webpack://./../../libs/remix-ui/file-explorer/src/lib/css/file-explorer.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,uBAAuB;IACvB,uBAAuB;AAC3B;AACA;IACI,8BAA8B;IAC9B,gCAAwB;OAAxB,6BAAwB;YAAxB,wBAAwB;AAC5B;AACA;IACI,aAAa;AACjB;AACA;;IAEI,wBAAwB;IACxB,2BAA2B;AAC/B;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,8BAA8B;AAClC;AACA;IACI,wBAAwB;AAC5B;AACA;IACI;AACJ;AACA;IACI,wBAAwB;IACxB,uBAAuB;IACvB,uBAAuB;AAC3B;AACA;IACI,wBAAwB;IACxB,wBAAwB;IACxB,wBAAwB;IACxB,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".remixui_label {\n    margin-top        : 4px;\n}\n.remixui_leaf {\n    overflow          : hidden;\n    text-overflow     : ellipsis;\n    width             : 90%;\n    margin-bottom     : 0px;\n}\n.remixui_fileexplorer       {\n    box-sizing        : border-box;\n    user-select       : none;\n}\ninput[type=\"file\"] {\n    display: none;\n}\n.remixui_folder,\n.remixui_file               {\n    font-size         : 14px;\n    cursor            : pointer;\n}\n.remixui_file               {\n    padding           : 4px;\n}\n.remixui_newFile            {\n    padding-right     : 10px;\n}\n.remixui_newFile i          {\n    cursor            : pointer;\n}\n.remixui_newFile:hover    {\n    transform         : scale(1.3);\n}\n.remixui_menu               {\n    margin-left       : 20px;\n}\n.remixui_items              {\n    display           : inline\n}\n.remixui_remove             {\n    margin-left       : auto;\n    padding-left      : 5px;\n    padding-right     : 5px;\n}\n.remixui_activeMode         {\n    display           : flex;\n    width             : 100%;\n    margin-right      : 10px;\n    padding-right     : 19px;\n}\n.remixui_activeMode > div   {\n    min-width         : 10px;\n}\nul                  {\n    padding           : 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
