// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remixui_sol.success,
.remixui_sol.error,
.remixui_sol.warning {
    white-space: pre-line;
    word-wrap: break-word;
    cursor: pointer;
    position: relative;
    margin: 0.5em 0 1em 0;
    border-radius: 5px;
    line-height: 20px;
    padding: 8px 15px;
}

.remixui_sol.success pre,
.remixui_sol.error pre,
.remixui_sol.warning pre {
    white-space: pre-line;
    overflow-y: hidden;
    background-color: transparent;
    margin: 0;
    font-size: 12px;
    border: 0 none;
    padding: 0;
    border-radius: 0;
}

.remixui_sol.success .close,
.remixui_sol.error .close,
.remixui_sol.warning .close {
    white-space: pre-line;
    font-weight: bold;
    position: absolute;
    color: hsl(0, 0%, 0%); /* black in style-guide.js */
    top: 0;
    right: 0;
    padding: 0.5em;
}

.remixui_sol.error {
}

.remixui_sol.warning {
}

.remixui_sol.success {
  /* background-color:  // styles.rightPanel.message_Success_BackgroundColor; */
}
`, "",{"version":3,"sources":["webpack://./../../libs/remix-ui/renderer/src/lib/renderer.css"],"names":[],"mappings":"AAAA;;;IAGI,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;;;IAGI,qBAAqB;IACrB,kBAAkB;IAClB,6BAA6B;IAC7B,SAAS;IACT,eAAe;IACf,cAAc;IACd,UAAU;IACV,gBAAgB;AACpB;;AAEA;;;IAGI,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB,EAAE,4BAA4B;IACnD,MAAM;IACN,QAAQ;IACR,cAAc;AAClB;;AAEA;AACA;;AAEA;AACA;;AAEA;EACE,6EAA6E;AAC/E","sourcesContent":[".remixui_sol.success,\n.remixui_sol.error,\n.remixui_sol.warning {\n    white-space: pre-line;\n    word-wrap: break-word;\n    cursor: pointer;\n    position: relative;\n    margin: 0.5em 0 1em 0;\n    border-radius: 5px;\n    line-height: 20px;\n    padding: 8px 15px;\n}\n\n.remixui_sol.success pre,\n.remixui_sol.error pre,\n.remixui_sol.warning pre {\n    white-space: pre-line;\n    overflow-y: hidden;\n    background-color: transparent;\n    margin: 0;\n    font-size: 12px;\n    border: 0 none;\n    padding: 0;\n    border-radius: 0;\n}\n\n.remixui_sol.success .close,\n.remixui_sol.error .close,\n.remixui_sol.warning .close {\n    white-space: pre-line;\n    font-weight: bold;\n    position: absolute;\n    color: hsl(0, 0%, 0%); /* black in style-guide.js */\n    top: 0;\n    right: 0;\n    padding: 0.5em;\n}\n\n.remixui_sol.error {\n}\n\n.remixui_sol.warning {\n}\n\n.remixui_sol.success {\n  /* background-color:  // styles.rightPanel.message_Success_BackgroundColor; */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
