const Web3 = require('web3')
const { BN, privateToAddress } = require('@tvmjs/util')
const Account = require('eth-lib/lib/account');
const { Provider, extend } = require('@remix-project/remix-simulator')
const { hashPersonalMessage } = require('../../lib/helper')

class VMProvider {
  constructor (executionContext) {
    this.executionContext = executionContext
  }

  getAccounts (cb) {
    this.web3.eth.getAccounts((err, accounts) => {
      if (err) {
        return cb('No accounts?')
      }
      return cb(null, accounts)
    })
  }

  resetEnvironment () {
    this.accounts = {}
    this.RemixSimulatorProvider = new Provider({ fork: this.executionContext.getCurrentFork() })
    this.RemixSimulatorProvider.init()
    this.RemixSimulatorProvider.Accounts.resetAccounts()
    this.web3 = new Web3(this.RemixSimulatorProvider)
    extend(this.web3)
    this.accounts = {}
    this.executionContext.setWeb3('vm', this.web3)
  }

  // TODO: is still here because of the plugin API
  // can be removed later when we update the API
  createVMAccount (newAccount) {
    const { privateKey, balance } = newAccount
    this.RemixSimulatorProvider.Accounts._addAccount(privateKey, balance)
    const privKey = Buffer.from(privateKey, 'hex')
    return '0x' + privateToAddress(privKey).toString('hex')
  }

  newAccount (_passwordPromptCb, cb) {
    this.RemixSimulatorProvider.Accounts.newAccount(cb)
  }

  getAccount (address, cb) {
    this.RemixSimulatorProvider.Accounts.getAccount(address, cb)
  }

  setTRC10Balance (address, tokenId, tokenValue, cb) {
    this.RemixSimulatorProvider.Accounts.setTRC10Balance(address, tokenId, tokenValue, cb)
  }

  getBalanceInEther (address, cb) {
    this.web3.eth.getBalance(address, (err, res) => {
      if (err) {
        return cb(err)
      }
      cb(null, Web3.utils.fromWei(new BN(res).toString(10), 'mwei'))
    })
  }

  getGasPrice (cb) {
    this.web3.eth.getGasPrice(cb)
  }

  signMessage (message, account, _passphrase, cb) {
    const messageHash = hashPersonalMessage(Buffer.from(message))
    const privateKey = this.RemixSimulatorProvider.Accounts.accountsKeys[account]
    const signature = Account.sign(`0x${messageHash.toString('hex')}`, privateKey)
    cb(null, '0x' + messageHash.toString('hex'), signature);
  }

  getProvider () {
    return 'vm'
  }
}

module.exports = VMProvider
