'use strict';
import { EventManager } from '../eventManager';
import { addressToBase58 } from '../util';
import Web3 from 'web3';

export class TxRunnerWeb3 {
  event;
  _api;
  getWeb3: () => Web3;
  getTronWeb: () => any;
  currentblockGasLimit: () => number;

  constructor(api, getWeb3, currentblockGasLimit) {
    this.event = new EventManager();
    this.getWeb3 = getWeb3;
    this.getTronWeb = getWeb3;
    this.currentblockGasLimit = currentblockGasLimit;
    this._api = api;
  }

  _executeTx(tx, network, txFee, api, promptCb, callback) {
    if (network && network.lastBlock && network.lastBlock.baseFeePerGas) {
      // the sending stack (web3.js / metamask need to have the type defined)
      // this is to avoid the following issue: https://github.com/MetaMask/metamask-extension/issues/11824
      tx.type = '0x2';
    }
    if (txFee) {
      if (txFee.baseFeePerGas) {
        tx.maxPriorityFeePerGas = this.getWeb3().utils.toHex(
          this.getWeb3().utils.toWei(txFee.maxPriorityFee, 'gwei')
        );
        tx.maxFeePerGas = this.getWeb3().utils.toHex(
          this.getWeb3().utils.toWei(txFee.maxFee, 'gwei')
        );
        tx.type = '0x2';
      } else {
        tx.gasPrice = this.getWeb3().utils.toHex(
          this.getWeb3().utils.toWei(txFee.gasPrice, 'gwei')
        );
        tx.type = '0x1';
      }
    }

    if (api.personalMode()) {
      promptCb(
        value => {
          this._sendTransaction(
            (this.getWeb3() as any).personal.sendTransaction,
            tx,
            value,
            callback
          );
        },
        () => {
          return callback('Canceled by user.');
        }
      );
    } else {
      this._sendTransaction(
        this.getWeb3().eth.sendTransaction,
        tx,
        null,
        callback
      );
    }
  }

  _sendTransaction(sendTx, tx, pass, callback) {
    const cb = (err, resp) => {
      if (err) {
        return callback(err, resp);
      }
      this.event.trigger('transactionBroadcasted', [resp]);
      var listenOnResponse = () => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          const receipt = await tryTillReceiptAvailable(resp, this.getWeb3());
          tx = await tryTillTxAvailable(resp, this.getWeb3());
          resolve({
            receipt,
            tx,
            transactionHash: receipt ? receipt['transactionHash'] : null
          });
        });
      };
      listenOnResponse()
        .then(txData => {
          callback(null, txData);
        })
        .catch(error => {
          callback(error);
        });
    };
    const args = pass !== null ? [tx, pass, cb] : [tx, cb];
    try {
      sendTx.apply({}, args);
    } catch (e) {
      return callback(
        `Send transaction failed: ${e.message} . if you use an injected provider, please check it is properly unlocked. `
      );
    }
  }

  execute(args, confirmationCb, gasEstimationForceSend, promptCb, callback) {
    let data = args.data;
    if (data.slice(0, 2) !== '0x') {
      data = '0x' + data;
    }

    if (this._api && this._api.isVM()) {
      return this.runInNode(
        args.from,
        args.to,
        data,
        args.value,
        args.tokenId,
        args.tokenValue,
        args.gasLimit,
        args.useCall,
        args.timestamp,
        confirmationCb,
        gasEstimationForceSend,
        promptCb,
        callback
      );
    } else {
      return this.runInTron(
        args,
        confirmationCb,
        gasEstimationForceSend,
        promptCb,
        callback
      );
    }
  }

  runInNode(
    from,
    to,
    data,
    value,
    tokenId,
    tokenValue,
    gasLimit,
    useCall,
    timestamp,
    confirmCb,
    gasEstimationForceSend,
    promptCb,
    callback
  ) {
    const tx = {
      from: from,
      to: to,
      data: data,
      value: value,
      tokenId: tokenId,
      tokenValue: tokenValue
    };

    if (useCall) {
      tx['gas'] = gasLimit;
      if (this._api && this._api.isVM()) tx['timestamp'] = timestamp;
      return this.getWeb3().eth.call(tx, function(error, result: any) {
        if (error) return callback(error);
        callback(null, {
          result: result
        });
      });
    }
    this.getWeb3().eth.estimateGas(tx, (err, gasEstimation) => {
      if (err && err.message.indexOf('Invalid JSON RPC response') !== -1) {
        // // @todo(#378) this should be removed when https://github.com/WalletConnect/walletconnect-monorepo/issues/334 is fixed
        callback(
          new Error(
            'Gas estimation failed because of an unknown internal error. This may indicated that the transaction will fail.'
          )
        );
      }
      gasEstimationForceSend(
        err,
        () => {
          // callback is called whenever no error
          tx['gas'] = !gasEstimation ? gasLimit : gasEstimation;

          this._api.detectNetwork((err, network) => {
            if (err) {
              console.log(err);
              return;
            }

            if (
              this._api.config.getUnpersistedProperty(
                'doNotShowTransactionConfirmationAgain'
              )
            ) {
              return this._executeTx(
                tx,
                network,
                null,
                this._api,
                promptCb,
                callback
              );
            }

            confirmCb(
              network,
              tx,
              tx['gas'],
              txFee => {
                return this._executeTx(
                  tx,
                  network,
                  txFee,
                  this._api,
                  promptCb,
                  callback
                );
              },
              error => {
                callback(error);
              }
            );
          });
        },
        () => {
          const blockGasLimit = this.currentblockGasLimit();
          // NOTE: estimateGas very likely will return a large limit if execution of the code failed
          //       we want to be able to run the code in order to debug and find the cause for the failure
          if (err) return callback(err);

          let warnEstimation =
            " An important gas estimation might also be the sign of a problem in the contract code. Please check loops and be sure you did not sent value to a non payable function (that's also the reason of strong gas estimation). ";
          warnEstimation += ' ' + err;

          if (gasEstimation > gasLimit) {
            return callback(
              'Gas required exceeds limit: ' + gasLimit + '. ' + warnEstimation
            );
          }
          if (gasEstimation > blockGasLimit) {
            return callback(
              'Gas required exceeds block gas limit: ' +
                gasLimit +
                '. ' +
                warnEstimation
            );
          }
        }
      );
    });
  }

  runInTron(args, confirmCb, gasEstimationForceSend, promptCb, callback) {
    const {
      from,
      to,
      funAbi,
      data,
      value,
      tokenId: tokenIdHex,
      tokenValue: tokenValueHex,
      gasLimit,
      contractName: name = '',
      contractABI = [],
      userFeePercentage,
      originEnergyLimit,
      useCall
    } = args;

    const contractAddr = addressToBase58(to);
    const callValue = parseInt(value, 10);
    const tokenId = parseInt(tokenIdHex, 16);
    const tokenValue = parseInt(tokenValueHex, 16);
    const feeLimit = parseInt(gasLimit, 16);
    const rawParameter = data ? data.replace(/^(0x)/, '').slice(8) : '';
    let functionSelector = '';
    if (funAbi) {
      // java-tron trick
      if (funAbi.type === 'fallback') {
        funAbi.name = 'fallback';
        funAbi.inputs = [];
      }

      if (funAbi.type !== 'receive' && funAbi.type !== 'constructor') {
        // web3 ts trick
        // @ts-ignore
        functionSelector = Web3.utils._jsonInterfaceMethodToString(funAbi);
      }
    }

    const tronWebIns = this.getTronWeb();

    if (useCall) {
      return tronWebIns.transactionBuilder.triggerSmartContract(
        contractAddr,
        functionSelector,
        { _isConstant: true, rawParameter },
        (error, result) => {
          if (error) return callback(error);
          const res = result && result.result ? result.constant_result : [''];
          callback(null, { result: res[0] });
        }
      );
    }

    gasEstimationForceSend(
      null,
      async () => {
        const tryTillTxAvailableTron = async txhash => {
          try {
            const receipt = await tronWebIns.trx.getUnconfirmedTransactionInfo(
              txhash
            );
            if (receipt.id) return receipt;
          } catch (e) {}
          await pause();
          return await tryTillTxAvailableTron(txhash);
        };

        const cb = (err, resp) => {
          if (err) {
            return callback(err, resp);
          }
          this.event.trigger('transactionBroadcasted', [resp]);
          var listenOnResponse = () => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
              const txn = await tryTillTxAvailableTron(resp);
              if (txn.result === 'FAILED') {
                let msg = txn.receipt.result ? txn.receipt.result : 'Unknown';
                if (msg !== 'REVERT') return reject(msg);

                try {
                  const contractResult = txn.contractResult
                    ? txn.contractResult[0]
                    : '';
                  const hexStr = contractResult.substr(8);
                  const strIndex = Web3.utils.hexToNumber(
                    `0x${hexStr.substr(0, 64)}`
                  ) as number;
                  const strLength = Web3.utils.hexToNumber(
                    `0x${hexStr.substr(strIndex * 2, 64)}`
                  ) as number;
                  if (strLength) {
                    msg = Web3.utils.hexToUtf8(
                      `0x${hexStr.substr(strIndex * 2 + 64, strLength * 2)}`
                    );
                  }
                  reject(msg);
                } catch (error) {
                  reject(error);
                }
              } else {
                const transactionHash = `0x${resp}`;
                const { blockNumber, fee } = txn;
                const contractAddress = txn.contract_address.replace(
                  /^(41)/,
                  '0x'
                );

                const receipt = {
                  blockNumber,
                  contractAddress,
                  gasUsed: fee,
                  logs: [],
                  status: true,
                  transactionHash
                };
                const tx = {
                  blockNumber,
                  from,
                  to: contractAddr,
                  gas: fee,
                  gasPrice: '',
                  hash: transactionHash,
                  input: data.slice(0, 2) !== '0x' ? '0x' + data : data,
                  value,
                  tokenId,
                  tokenValue
                };

                resolve({ receipt, tx, transactionHash });
              }
            });
          };
          listenOnResponse()
            .then(txData => {
              callback(null, txData);
            })
            .catch(error => {
              callback(error);
            });
        };

        try {
          if (contractAddr) {
            const tTransaction = await tronWebIns.transactionBuilder.triggerSmartContract(
              contractAddr,
              functionSelector,
              { callValue, tokenId, tokenValue, feeLimit, rawParameter },
              []
            );

            if (!tTransaction.result) {
              throw new Error('Unknown');
            }

            if (!tTransaction.result.result) {
              throw new Error(
                tTransaction.result.code ? tTransaction.result.code : 'Unknown'
              );
            }

            const tSignedTransaction = await tronWebIns.trx.sign(
              tTransaction.transaction
            );
            const tResult = await tronWebIns.trx.sendRawTransaction(
              tSignedTransaction
            );
            const tResp =
              tResult && tResult.result
                ? { txhash: tResult.transaction.txID }
                : {};
            if (tResp.txhash) {
              cb(null, tResp.txhash);
            } else {
              throw new Error('Broadcast failed');
            }
          } else {
            const dTransaction = await tronWebIns.transactionBuilder.createSmartContract(
              {
                abi: contractABI,
                bytecode: data,
                rawParameter: '0x',
                name,
                callValue,
                tokenId,
                tokenValue,
                feeLimit,
                userFeePercentage,
                originEnergyLimit
              }
            );
            const dSignedTransaction = await tronWebIns.trx.sign(dTransaction);
            const dResult = await tronWebIns.trx.sendRawTransaction(
              dSignedTransaction
            );
            const dResp =
              dResult && dResult.result
                ? {
                    address: dResult.transaction.contract_address,
                    txhash: dResult.transaction.txID
                  }
                : {};
            if (dResp.txhash) {
              cb(null, dResp.txhash);
            } else {
              throw new Error('Broadcast failed');
            }
          }
        } catch (e) {
          return callback(
            `Send transaction failed: ${
              e.message ? e.message : e
            } . if you use an injected provider, please check it is properly unlocked. `
          );
        }
      },
      () => {}
    );
  }
}

async function tryTillReceiptAvailable(txhash, web3) {
  try {
    const receipt = await web3.eth.getTransactionReceipt(txhash);
    if (receipt) return receipt;
  } catch (e) {}
  await pause();
  return await tryTillReceiptAvailable(txhash, web3);
}

async function tryTillTxAvailable(txhash, web3) {
  try {
    const tx = await web3.eth.getTransaction(txhash);
    if (tx) return tx;
  } catch (e) {}
  return await tryTillTxAvailable(txhash, web3);
}

async function pause() {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, 500);
  });
}
