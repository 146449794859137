// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.li_tv {
    list-style-type: none;
    -webkit-margin-before: 0px;
    -webkit-margin-after: 0px;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
  }
  .ul_tv {
    list-style-type: none;
    -webkit-margin-before: 0px;
    -webkit-margin-after: 0px;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
  }
  .caret_tv {
    width: 10px;
    flex-shrink: 0;
    padding-right: 5px;
  }
  .label_item {
    word-break: break-all;
  }
  .label_key {
    min-width: 15%;
    max-width: 80%;
    word-break: break-word;
  }
  .label_value {
    min-width: 10%;
  }`, "",{"version":3,"sources":["webpack://./../../libs/remix-ui/tree-view/src/lib/remix-ui-tree-view.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,0BAA0B;IAC1B,yBAAyB;IACzB,yBAAyB;IACzB,uBAAuB;IACvB,0BAA0B;EAC5B;EACA;IACE,qBAAqB;IACrB,0BAA0B;IAC1B,yBAAyB;IACzB,yBAAyB;IACzB,uBAAuB;IACvB,0BAA0B;EAC5B;EACA;IACE,WAAW;IACX,cAAc;IACd,kBAAkB;EACpB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,cAAc;IACd,cAAc;IACd,sBAAsB;EACxB;EACA;IACE,cAAc;EAChB","sourcesContent":[".li_tv {\n    list-style-type: none;\n    -webkit-margin-before: 0px;\n    -webkit-margin-after: 0px;\n    -webkit-margin-start: 0px;\n    -webkit-margin-end: 0px;\n    -webkit-padding-start: 0px;\n  }\n  .ul_tv {\n    list-style-type: none;\n    -webkit-margin-before: 0px;\n    -webkit-margin-after: 0px;\n    -webkit-margin-start: 0px;\n    -webkit-margin-end: 0px;\n    -webkit-padding-start: 0px;\n  }\n  .caret_tv {\n    width: 10px;\n    flex-shrink: 0;\n    padding-right: 5px;\n  }\n  .label_item {\n    word-break: break-all;\n  }\n  .label_key {\n    min-width: 15%;\n    max-width: 80%;\n    word-break: break-word;\n  }\n  .label_value {\n    min-width: 10%;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
