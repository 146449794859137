// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remixui_contextContainer
{
  display: block;
  position: fixed;
  border-radius: 2px;
  z-index: 1000;
  box-shadow: 0 0 4px var(--dark);
}
.remixui_contextContainer:focus {
    outline: none;
}
.remixui_liitem
{
  padding: 2px;
  padding-left: 6px;
  cursor: pointer;
  color: var(--text-dark);
  background-color: var(--light);
}
.remixui_liitem:hover
{
  background-color:  var(--secondary);
}
#remixui_menuitems
{
  list-style: none;
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./../../libs/remix-ui/file-explorer/src/lib/css/file-explorer-context-menu.css"],"names":[],"mappings":"AAAA;;EAEE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,+BAA+B;AACjC;AACA;IACI,aAAa;AACjB;AACA;;EAEE,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,uBAAuB;EACvB,8BAA8B;AAChC;AACA;;EAEE,mCAAmC;AACrC;AACA;;EAEE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".remixui_contextContainer\n{\n  display: block;\n  position: fixed;\n  border-radius: 2px;\n  z-index: 1000;\n  box-shadow: 0 0 4px var(--dark);\n}\n.remixui_contextContainer:focus {\n    outline: none;\n}\n.remixui_liitem\n{\n  padding: 2px;\n  padding-left: 6px;\n  cursor: pointer;\n  color: var(--text-dark);\n  background-color: var(--light);\n}\n.remixui_liitem:hover\n{\n  background-color:  var(--secondary);\n}\n#remixui_menuitems\n{\n  list-style: none;\n  margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
