// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    flex-direction: column;
}
.txContainer {
    display: flex;
    flex-direction: column;
}
.txinput {
    width: inherit;
    font-size: small;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.txbutton {
    width: inherit;
}
.txbutton:hover {
}
.vmargin {
    margin-top: 10px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./../../libs/remix-ui/debugger-ui/src/lib/tx-browser/tx-browser.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,cAAc;AAClB;AACA;AACA;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n}\n.txContainer {\n    display: flex;\n    flex-direction: column;\n}\n.txinput {\n    width: inherit;\n    font-size: small;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.txbutton {\n    width: inherit;\n}\n.txbutton:hover {\n}\n.vmargin {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
