const Web3 = require('web3')
const { hashPersonalMessage } = require('../../lib/helper')

class InjectedProvider {
  constructor (executionContext) {
    this.executionContext = executionContext
  }

  getAccounts (cb) {
    return cb(null, [this.executionContext.web3().defaultAddress.base58])
  }

  newAccount (passwordPromptCb, cb) {
    passwordPromptCb((passphrase) => {
      this.executionContext.web3().personal.newAccount(passphrase, cb)
    })
  }

  resetEnvironment () {
  }

  getBalanceInEther (address, cb) {
    this.executionContext.web3().trx.getBalance(address, (err, res) => {
      if (err) {
        return cb(err)
      }
      cb(null, Web3.utils.fromWei(res.toString(10), 'picoether'))
    })
  }

  getGasPrice (cb) {
    cb(null, 1)
  }

  signMessage (message, account, _passphrase, cb) {
    const messageHash = '0x' + Buffer.from(message).toString('hex')
    try {
      this.executionContext.web3().trx.sign(messageHash, (error, signedData) => {
        const messageHash = '0x' + hashPersonalMessage(Buffer.from(message)).toString('hex')
        cb(error, messageHash, signedData)
      })
    } catch (e) {
      cb(e.message)
    }
  }

  getProvider () {
    return 'injected'
  }
}

module.exports = InjectedProvider
