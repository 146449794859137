var csjs = require('csjs-inject')

var css = csjs`
  .prompt_text {
    width: 100%;
  }
  .prompt_tokens {
    margin: 10px 0;
  }
  .prompt_token_row {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    border-bottom: solid 2px var(--secondary);
  }
  .prompt_token_row_set {
    display: flex;
    width: 100%;
    margin: 20px 0 0;
  }
  .prompt_token_id {
    width: 30%;
    margin-right: 20px;
  }
  .prompt_token_val {
    width: 40%;
    margin-right: 20px;
  }
  .prompt_token_set {
    width: 20%
  }
  .prompt_token_error {
    margin-top: 5px;
    color: red;
  }
`

module.exports = css
