import { BN } from '@tvmjs/util'
const $ = require('jquery')
const yo = require('yo-yo')
const remixLib = require('@remix-project/remix-lib')
const EventManager = remixLib.EventManager
const css = require('../styles/run-tab-styles')
const copyToClipboard = require('../../ui/copy-to-clipboard')
const modalDialogCustom = require('../../ui/modal-dialog-custom')
const addTooltip = require('../../ui/tooltip')
const helper = require('../../../lib/helper.js')
const globalRegistry = require('../../../global/registry')

class SettingsUI {
  constructor (blockchain, networkModule) {
    this.blockchain = blockchain
    this.event = new EventManager()
    this._components = {}

    this.blockchain.event.register('transactionExecuted', (error, from, to, data, lookupOnly, txResult) => {
      if (!lookupOnly) {
        this.el.querySelector('#value').value = 0
        this.el.querySelector('#tokenId').value = 0
        this.el.querySelector('#tokenValue').value = 0
      }
      if (error) return
      this.updateAccountBalances()
    })
    this._components = {
      registry: globalRegistry,
      networkModule: networkModule
    }
    this._components.registry = globalRegistry
    this._deps = {
      config: this._components.registry.get('config').api
    }

    this._deps.config.events.on('settings/personal-mode_changed', this.onPersonalChange.bind(this))

    setInterval(() => {
      this.updateAccountBalances()
    }, 3000)

    this.accountListCallId = 0
    this.loadedAccounts = {}
  }

  updateAccountBalances () {
    if (!this.el) return
    var accounts = $(this.el.querySelector('#txorigin')).children('option')
    accounts.each((index, account) => {
      this.blockchain.getBalanceInEther(account.value, (err, balance) => {
        if (err) return
        const updated = helper.shortenAddress(account.value, balance)
        if (updated !== account.innerText) { // check if the balance has been updated and update UI accordingly.
          account.innerText = updated
        }
      })
    })
  }

  validateInputKey (e) {
    // preventing not numeric keys
    // preventing 000 case
    if (!helper.isNumeric(e.key) ||
      (e.key === '0' && !parseInt(this.el.querySelector('#value').value) && this.el.querySelector('#value').value.length > 0)) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  }

  validateValue () {
    const valueEl = this.el.querySelector('#value')
    if (!valueEl.value) {
      // assign 0 if given value is
      // - empty
      valueEl.value = 0
      return
    }

    let v
    try {
      v = new BN(valueEl.value, 10)
      valueEl.value = v.toString(10)
    } catch (e) {
      // assign 0 if given value is
      // - not valid (for ex 4345-54)
      // - contains only '0's (for ex 0000) copy past or edit
      valueEl.value = 0
    }

    // if giveen value is negative(possible with copy-pasting) set to 0
    if (v.lt(0)) valueEl.value = 0
  }

  validateInputKeyExtend (e, id) {
    const qs = `#${id}`
    // preventing not numeric keys
    // preventing 000 case
    if (!helper.isNumeric(e.key) ||
      (e.key === '0' && !parseInt(this.el.querySelector(qs).value) && this.el.querySelector(qs).value.length > 0)) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  }

  validateValueExtend (id) {
    const valueEl = this.el.querySelector(`#${id}`)
    if (!valueEl.value) {
      // assign 0 if given value is
      // - empty
      valueEl.value = 0
      return
    }

    let v
    try {
      v = new BN(valueEl.value, 10)
      valueEl.value = v.toString(10)
    } catch (e) {
      // assign 0 if given value is
      // - not valid (for ex 4345-54)
      // - contains only '0's (for ex 0000) copy past or edit
      valueEl.value = 0
    }

    // if giveen value is negative(possible with copy-pasting) set to 0
    if (v.lt(0)) valueEl.value = 0

    if (id === 'userFeePer') {
      if (v.gtn(100)) valueEl.value = 100
    }
    if (id === 'originEnergy') {
      if (v.gtn(10000000)) valueEl.value = 10000000
    }
  }

  getAddress () {
    const address = document.querySelector('#runTabView #txorigin').value
    return remixLib.util.addressToBase58(address)
  }

  render () {
    this.netUI = yo`<span class="${css.network} badge badge-secondary"></span>`

    var environmentEl = yo`
      <div class="${css.crow}">
        <label id="selectExEnv" class="${css.settingsLabel}">
          Environment
        </label>
        <div class="${css.environment}">
          <select id="selectExEnvOptions" data-id="settingsSelectEnvOptions" class="form-control ${css.select} custom-select">
            <option id="vm-mode-tron" data-id="settingsVMLondonMode"
              title="Execution environment does not connect to any node, everything is local and in memory only."
              value="vm-tron" name="executionContext" fork="tron"> JavaScript VM (Tron)
            </option>
            <option id="injected-mode" data-id="settingsInjectedMode"
              title="Execution environment has been provided by TronLink or similar provider."
              value="injected" name="executionContext"> Injected TronWeb
            </option>
          </select>
        </div>
      </div>
    `
    const networkEl = yo`
    <div class="${css.crow}">
        <div class="${css.settingsLabel}">
        </div>
        <div class="${css.environment}" data-id="settingsNetworkEnv">
          ${this.netUI}
        </div>
      </div>
    `
    const accountEl = yo`
      <div class="${css.crow}">
        <label class="${css.settingsLabel}">
          Account
          <span id="remixRunPlusWrapper" title="Create a new account" onload=${this.updatePlusButton.bind(this)}>
            <i id="remixRunPlus" class="fas fa-plus-circle ${css.icon}" aria-hidden="true" onclick=${this.newAccount.bind(this)}"></i>
          </span>
        </label>
        <div class="${css.account}">
          <select data-id="runTabSelectAccount" name="txorigin" class="form-control ${css.select} custom-select pr-4" id="txorigin"></select>
          <div style="margin-left: -5px;">${copyToClipboard(this.getAddress)}</div>
          <i id="remixRunSignMsg" data-id="settingsRemixRunSignMsg" class="mx-1 fas fa-edit ${css.icon}" aria-hidden="true" onclick=${this.signMessage.bind(this)} title="Sign a message using this account key"></i>
        </div>
      </div>
    `

    const gasPriceEl = yo`
      <div class="${css.crow}">
        <label class="${css.settingsLabel}">Fee limit</label>
        <input type="number" class="form-control ${css.gasNval} ${css.col2}" id="gasLimit" value="400000000">
      </div>
    `

    const valueEl = yo`
      <div class="${css.crow}">
        <label class="${css.settingsLabel}" data-id="remixDRValueLabel">Value</label>
        <div class="${css.gasValueContainer}">
          <input
            type="number"
            min="0"
            pattern="^[0-9]"
            step="1"
            class="form-control ${css.gasNval} ${css.col2}"
            id="value"
            data-id="dandrValue"
            value="0"
            title="Enter the value and choose the unit"
            onkeypress=${(e) => this.validateInputKey(e)}
            onchange=${() => this.validateValue()}
          >
          <select name="unit" class="form-control ${css.gasNvalUnit} ${css.col2_2} custom-select" id="unit">
            <option data-unit="wei">sun</option>
            <option data-unit="mwei">trx</option>
          </select>
        </div>
      </div>
    `

    const extendEl = yo`
      <div class="${css.crow}">
        <div class="${css.gasValueContainer}">
          <div class="${css.gasNTid}">
            <label class="${css.settingsLabel}" data-id="remixDRTokenIdLabel">
            Token Id
            <span id="remixTokenPlusWrapper" title="Set TRC10 balance" onload=${this.updateTokenPlusButton.bind(this)}>
              <i id="remixTokenPlus" class="fas fa-plus-circle ${css.icon}" aria-hidden="true" onclick=${this.newToken.bind(this)}"></i>
            </span>
          </label>
            <input
              type="number"
              min="1000001"
              pattern="^[0-9]"
              step="1"
              class="form-control ${css.col2}"
              id="tokenId"
              data-id="dandrTokenId"
              value="0"
              title="Enter the trc10 id"
              onkeypress=${(e) => this.validateInputKeyExtend(e, 'tokenId')}
              onchange=${() => this.validateValueExtend('tokenId')}
            >
          </div>
          <div class="${css.gasNTval}">
            <label class="${css.settingsLabel}" data-id="remixDRTokenValueLabel">Token value</label>
            <input
              type="number"
              min="0"
              pattern="^[0-9]"
              step="1"
              class="form-control ${css.col2}"
              id="tokenValue"
              data-id="dandrTokenValue"
              value="0"
              title="Enter the trc10 value"
              onkeypress=${(e) => this.validateInputKeyExtend(e, 'tokenValue')}
              onchange=${() => this.validateValueExtend('tokenValue')}
            >
          </div>
        </div>
      </div>
      <div id="userFeePerWrapper" class="${css.crow}">
        <label class="${css.settingsLabel}" data-id="remixDRFeePerLabel">User Fee Percentage</label>
        <div class="${css.gasValueContainer}">
          <input
            type="number"
            min="0"
            pattern="^[0-9]"
            step="1"
            class="form-control ${css.gasNval} ${css.col2}"
            id="userFeePer"
            data-id="dandrFeePerValue"
            value="100"
            title="Enter the user fee percentage"
            onkeypress=${(e) => this.validateInputKeyExtend(e, 'userFeePer')}
            onchange=${() => this.validateValueExtend('userFeePer')}
          >
        </div>
      </div>
      <div id="originEnergyWrapper" class="${css.crow}">
        <label class="${css.settingsLabel}" data-id="remixDROriginEnergyLabel">Origin Energy Limit</label>
        <div class="${css.gasValueContainer}">
          <input
            type="number"
            min="0"
            pattern="^[0-9]"
            step="1"
            class="form-control ${css.gasNval} ${css.col2}"
            id="originEnergy"
            data-id="dandrOriginEnergyValue"
            value="10000000"
            title="Enter the origin energy limit"
            onkeypress=${(e) => this.validateInputKeyExtend(e, 'originEnergy')}
            onchange=${() => this.validateValueExtend('originEnergy')}
          >
        </div>
      </div>
    `

    const extendWrapperEl = yo`
      <div>
        <div id="extendwrapper" class="${css.extendWrapper}">
          ${extendEl}
        </div>
        <p class="${css.extendBtn}" onclick="${() => showExtend()}">
          <i id="extendbtn" class="fas fa-angle-down"></i>
        </p>
      </div>
    `

    const el = yo`
      <div class="${css.settings}">
        ${environmentEl}
        ${networkEl}
        ${accountEl}
        ${gasPriceEl}
        ${valueEl}
        ${extendWrapperEl}
      </div>
    `

    var showExtend = () => {
      var extendBtn = extendWrapperEl.querySelector('#extendbtn')
      var extendWrapper = extendWrapperEl.querySelector('#extendwrapper')

      if (extendBtn.classList) {
        extendBtn.classList.toggle('fa-angle-up')
        var down = extendBtn.classList.toggle('fa-angle-down')
        if (down) $(extendWrapper).hide()
        else $(extendWrapper).show()
      }
    }

    var selectExEnv = environmentEl.querySelector('#selectExEnvOptions')
    this.setDropdown(selectExEnv)

    this.blockchain.event.register('contextChanged', (context, silent) => {
      this.setFinalContext()
    })

    this.blockchain.event.register('networkStatus', ({ error, network }) => {
      if (error) {
        this.netUI.innerHTML = 'can\'t detect network '
        return
      }
      const networkProvider = this._components.networkModule.getNetworkProvider.bind(this._components.networkModule)
      this.netUI.innerHTML = (networkProvider() !== 'vm') ? `${network.name} (${network.id || '-'}) network` : ''
    })

    setInterval(() => {
      this.fillAccountsList()
    }, 1000)

    this.el = el

    this.fillAccountsList()
    this.setFinalContextAfterLoad()
    return el
  }

  setDropdown (selectExEnv) {
    this.selectExEnv = selectExEnv

    const addProvider = (network) => {
      selectExEnv.appendChild(yo`<option
        title="provider name: ${network.name}"
        value="${network.name}"
        name="executionContext"
      >
        ${network.name}
      </option>`)
      addTooltip(yo`<span><b>${network.name}</b> provider added</span>`)
    }

    const removeProvider = (name) => {
      var env = selectExEnv.querySelector(`option[value="${name}"]`)
      if (env) {
        selectExEnv.removeChild(env)
        addTooltip(yo`<span><b>${name}</b> provider removed</span>`)
      }
    }
    this.blockchain.event.register('addProvider', provider => addProvider(provider))
    this.blockchain.event.register('removeProvider', name => removeProvider(name))

    selectExEnv.addEventListener('change', (event) => {
      const provider = selectExEnv.options[selectExEnv.selectedIndex]
      const fork = provider.getAttribute('fork') // can be undefined if connected to an external source (web3 provider / injected)
      let context = provider.value
      context = context.startsWith('vm') ? 'vm' : context // context has to be 'vm', 'web3' or 'injected'
      this.setExecutionContext({ context, fork })
    })

    selectExEnv.value = this._getProviderDropdownValue()
  }

  setExecutionContext (context) {
    this.blockchain.changeExecutionContext(context, () => {
      modalDialogCustom.prompt('External node request', this.web3ProviderDialogBody(), 'http://127.0.0.1:8545', (target) => {
        this.blockchain.setProviderFromEndpoint(target, context, (alertMsg) => {
          if (alertMsg) addTooltip(alertMsg)
          this.setFinalContext()
        })
      }, this.setFinalContext.bind(this))
    }, (alertMsg) => {
      addTooltip(alertMsg)
    }, this.setFinalContext.bind(this))
  }

  web3ProviderDialogBody () {
    const thePath = '<path/to/local/folder/for/test/chain>'

    return yo`
      <div class="">
        Note: To use Geth & https://remix.ethereum.org, configure it to allow requests from Remix:(see <a href="https://geth.ethereum.org/docs/rpc/server" target="_blank">Geth Docs on rpc server</a>)
        <div class="border p-1">geth --http --http.corsdomain https://remix.ethereum.org</div>
        <br>
        To run Remix & a local Geth test node, use this command: (see <a href="https://geth.ethereum.org/getting-started/dev-mode" target="_blank">Geth Docs on Dev mode</a>)
        <div class="border p-1">geth --http --http.corsdomain="${window.origin}" --http.api web3,eth,debug,personal,net --vmdebug --datadir ${thePath} --dev console</div>
        <br>
        <br>
        <b>WARNING:</b> It is not safe to use the --http.corsdomain flag with a wildcard: <b>--http.corsdomain *</b>
        <br>
        <br>For more info: <a href="https://remix-ide.readthedocs.io/en/latest/run.html#more-about-web3-provider" target="_blank">Remix Docs on Web3 Provider</a>
        <br>
        <br>
        Web3 Provider Endpoint
      </div>
    `
  }

  /**
   * generate a value used by the env dropdown list.
   * @return {String} - can return 'vm-berlin, 'vm-london', 'injected' or 'web3'
   */
  _getProviderDropdownValue () {
    const provider = this.blockchain.getProvider()
    const fork = this.blockchain.getCurrentFork()
    return provider === 'vm' ? provider + '-' + fork : provider
  }

  setFinalContext () {
    // set the final context. Cause it is possible that this is not the one we've originaly selected
    this.selectExEnv.value = this._getProviderDropdownValue()
    this.event.trigger('clearInstance', [])
    this.updatePlusButton()
    this.updateTokenPlusButton()
  }

  setFinalContextAfterLoad () {
    if (document.getElementById('remixRunPlus')) {
      this.selectExEnv.value = this._getProviderDropdownValue()
      this.updatePlusButton()
      this.updateTokenPlusButton()
    } else {
      setTimeout(() => {
        this.setFinalContextAfterLoad()
      }, 100)
    }
  }

  updatePlusButton () {
    // enable/disable + button
    const plusBtn = document.getElementById('remixRunPlus')
    const plusTitle = document.getElementById('remixRunPlusWrapper')
    const value = this.selectExEnv.value.split('-')[0]
    switch (value) {
      case 'injected':
        plusBtn.classList.add(css.disableMouseEvents)
        plusTitle.title = "Unfortunately it's not possible to create an account using injected TronWeb. Please create the account directly from your provider (i.e TronLink or other of the same type)."

        break
      case 'vm':
        plusBtn.classList.remove(css.disableMouseEvents)
        plusTitle.title = 'Create a new account'

        break
      case 'web3':
        this.onPersonalChange()

        break
      default: {
        plusBtn.classList.add(css.disableMouseEvents)
        plusTitle.title = `Unfortunately it's not possible to create an account using an external wallet (${this.selectExEnv.value}).`
      }
    }
  }

  updateTokenPlusButton () {
    // enable/disable + button
    const plusBtn = document.getElementById('remixTokenPlus')
    const plusTitle = document.getElementById('remixTokenPlusWrapper')
    const userFeeEl = document.getElementById('userFeePerWrapper')
    const originEnergyEl = document.getElementById('originEnergyWrapper')

    const value = this.selectExEnv.value.split('-')[0]
    switch (value) {
      case 'injected':
        plusBtn.classList.add(css.disableMouseEvents)
        plusTitle.title = 'Does not support to set TRC10 balance using injected TronWeb on remix.'

        userFeeEl.style.display = 'block'
        originEnergyEl.style.display = 'block'

        break
      case 'vm':
        plusBtn.classList.remove(css.disableMouseEvents)
        plusTitle.title = 'Set TRC10 balance'

        userFeeEl.style.display = 'none'
        originEnergyEl.style.display = 'none'

        break
      default: {
        plusBtn.classList.add(css.disableMouseEvents)
        plusTitle.title = `Unfortunately it's not possible to set TRC10 balance using an external wallet (${this.selectExEnv.value}).`

        userFeeEl.style.display = 'block'
        originEnergyEl.style.display = 'block'
      }
    }
  }

  onPersonalChange () {
    const plusBtn = document.getElementById('remixRunPlus')
    const plusTitle = document.getElementById('remixRunPlusWrapper')
    if (!this._deps.config.get('settings/personal-mode')) {
      plusBtn.classList.add(css.disableMouseEvents)
      plusTitle.title = 'Creating an account is possible only in Personal mode. Please go to Settings to enable it.'
    } else {
      plusBtn.classList.remove(css.disableMouseEvents)
      plusTitle.title = 'Create a new account'
    }
  }

  newAccount () {
    this.blockchain.newAccount(
      '',
      (cb) => {
        modalDialogCustom.promptPassphraseCreation((error, passphrase) => {
          if (error) {
            return modalDialogCustom.alert(error)
          }
          cb(passphrase)
        }, () => {})
      },
      (error, address) => {
        if (error) {
          return addTooltip('Cannot create an account: ' + error)
        }
        addTooltip(`account ${remixLib.util.addressToBase58(address)} created`)
      }
    )
  }

  newToken () {
    const provider = this.blockchain.getProvider()
    if (provider !== 'vm') return

    const address = document.querySelector('#runTabView #txorigin').value
    const curProvider = this.blockchain.getCurrentProvider()
    curProvider.getAccount(address, (error, account) => {
      if (error) {
        return modalDialogCustom.alert(error)
      }
      modalDialogCustom.promptTRC10Creation(address, account.asset, curProvider, (error) => {
        if (error) {
          return addTooltip(error)
        }
      })
    })
  }

  getSelectedAccount () {
    return this.el.querySelector('#txorigin').selectedOptions[0].value
  }

  getEnvironment () {
    return this.blockchain.getProvider()
  }

  signMessage () {
    this.blockchain.getAccounts((err, accounts) => {
      if (err) {
        return addTooltip(`Cannot get account list: ${err}`)
      }

      var signMessageDialog = { title: 'Sign a message', text: 'Enter a message to sign', inputvalue: 'Message to sign' }
      var $txOrigin = this.el.querySelector('#txorigin')
      if (!$txOrigin.selectedOptions[0] && (this.blockchain.isInjectedWeb3() || this.blockchain.isWeb3Provider())) {
        return addTooltip('Account list is empty, please make sure the current provider is properly connected to remix')
      }

      var account = $txOrigin.selectedOptions[0].value

      var promptCb = (passphrase) => {
        const modal = modalDialogCustom.promptMulti(signMessageDialog, (message) => {
          this.blockchain.signMessage(message, account, passphrase, (err, msgHash, signedData) => {
            if (err) {
              return addTooltip(err)
            }
            modal.hide()
            modalDialogCustom.alert(yo`
              <div>
                <b>hash:</b><br>
                <span id="remixRunSignMsgHash" data-id="settingsRemixRunSignMsgHash">${msgHash}</span>
                <br><b>signature:</b><br>
                <span id="remixRunSignMsgSignature" data-id="settingsRemixRunSignMsgSignature">${signedData}</span>
              </div>
            `)
          })
        }, false)
      }

      if (this.blockchain.isWeb3Provider()) {
        return modalDialogCustom.promptPassphrase(
          'Passphrase to sign a message',
          'Enter your passphrase for this account to sign the message',
          '',
          promptCb,
          false
        )
      }
      promptCb()
    })
  }

  // TODO: unclear what's the goal of accountListCallId, feels like it can be simplified
  async fillAccountsList () {
    this.accountListCallId++
    const callid = this.accountListCallId
    const txOrigin = this.el.querySelector('#txorigin')
    let accounts = []
    try {
      accounts = await this.blockchain.getAccounts()
    } catch (e) {
      addTooltip(`Cannot get account list: ${e}`)
    }
    if (!accounts) accounts = []
    if (this.accountListCallId > callid) return
    this.accountListCallId++
    for (const loadedaddress in this.loadedAccounts) {
      if (accounts.indexOf(loadedaddress) === -1) {
        txOrigin.removeChild(txOrigin.querySelector('option[value="' + loadedaddress + '"]'))
        delete this.loadedAccounts[loadedaddress]
      }
    }
    for (const i in accounts) {
      const address = accounts[i]
      const addressBase58 = remixLib.util.addressToBase58(address)
      if (!this.loadedAccounts[address]) {
        txOrigin.appendChild(yo`<option value="${address}" >${addressBase58}</option>`)
        this.loadedAccounts[address] = 1
      }
    }
    txOrigin.setAttribute('value', accounts[0])
  }
}

module.exports = SettingsUI
